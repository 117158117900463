import './ChatContainer.scss';
import { useCallback } from 'react';
import ChatWindow from '../ChatWindow/ChatWindow';
import ConversationSelectionMenu from '../Conversation/ConversationSelectionMenu';
import { IConversation } from '../../Models/Conversation';
import { useChatReducer } from '../../Hooks/useChatReducer';
import { useAskMaddiUser } from '../../Hooks/useAskMaddiUser';
import { useAskMaddiAPI } from '../../Hooks/useAskMaddiAPI';
import { 
    IMessage,
    HumanMessage,
    SystemMessage,
    AIMessage, 
} from '../../Models/Message';
import { DEFAULT_WELCOME_MESSAGE } from '../../Constants';
import {
    generatePersonalizediATOMessage,
    generatePersonalizeditimekeepMessage,
    generatePersonalizedmilanaMessage,
    generatePersonalizediviGlobalMessage,
    generatePersonalizediExpertMessage
} from '../../Constants/SystemMessages';

import { generatePersonalizedWelcomeMessage } from '../../Constants/SystemMessages';

export interface ChatContainerProps {
    indexName: string;
    systemMessage?: IMessage;
}

export default function ChatContainer({ indexName, systemMessage}: ChatContainerProps) {
    const askMaddiAPI = useAskMaddiAPI();
    const [user, setUser] = useAskMaddiUser();
    const [state, dispatch] = useChatReducer(indexName);

    switch (indexName) {
        case "ato":
            systemMessage = new SystemMessage(generatePersonalizediATOMessage(user.firstName, "Aderant Total Office"), new Date());
            break;
        case "itimekeep":
            systemMessage = new SystemMessage(generatePersonalizeditimekeepMessage(user.firstName, "Aderant iTimekeep"), new Date());
            break;
        case "milana":
            systemMessage = new SystemMessage(generatePersonalizedmilanaMessage(user.firstName, "Aderant Milana"), new Date());
            break;        
        case "vibyaderant":
            systemMessage = new SystemMessage(generatePersonalizediviGlobalMessage(user.firstName, "vi by Aderant"), new Date());
            break;
        // TODO: use route instead of hardcoded values
        case "expert":
        case "expert-v1":
        case "expert-v2":
        case "expert-v3":    
            systemMessage = new SystemMessage(generatePersonalizediExpertMessage(user.firstName, "Aderant Expert"), new Date());
            break;        
        default:
            systemMessage = systemMessage ?? new SystemMessage(generatePersonalizedWelcomeMessage(user.firstName), new Date());
            break;
    }    
    
    const createNewConversation = async (message:string) => {
        const newConversation:IConversation = {
            tenantId: user.tenantId,
            userId: user.id,
            firstMessageContent: message,
            indexName: indexName,
            datetime: new Date(),
        }
        const newConversationPromise = askMaddiAPI.Conversation.createConversation(newConversation);
        dispatch({ type: 'ADD_PENDING_PROMISE', payload: newConversationPromise });
        const newConversationResponse = await newConversationPromise;
        newConversation.id = newConversationResponse;
        dispatch({ type: 'REMOVE_PENDING_PROMISE', payload: newConversationPromise });
        dispatch({ type: 'ADD_CONVERSATION', payload: newConversation });
        dispatch({ type: 'SET_NEW_CONVERSATION_ID', payload: newConversation.id });
        dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: newConversation });
        return newConversation;
    }

    const handleClearConversation = () => {
        dispatch({ type: 'SET_NEW_CONVERSATION_ID', payload: undefined });
        dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: undefined });
        dispatch({ type: 'SET_MESSAGES', payload: [] });
    }

    const handleConversationSelect = (conversation: IConversation) => {
        dispatch({ type: 'SET_MESSAGES', payload: [] });
        dispatch({ type: 'SET_SELECTED_CONVERSATION', payload: conversation });
        if (state.newConversationId !== conversation.id) {
            dispatch({ type: 'SET_NEW_CONVERSATION_ID', payload: undefined });
        }
    }

    const handleConversationSerch =(searchTerm:string, start?:Date, end?:Date) => {
        const getConversations = async () => {
            let fetchedConversations = await askMaddiAPI?.Conversation.getUserConversations(user.id, indexName, searchTerm, start, end);
            dispatch({ type: 'SET_CONVERSATIONS', payload: fetchedConversations ?? [] });
        }
        getConversations();
    }

    const handleSendMessage = async (message:string) => {
        const newMessage = new HumanMessage(message, new Date(), { userId: user.id, tenantId: user.tenantId });
        dispatch({ type: 'ADD_MESSAGE', payload: newMessage });
        const conversation = state.selectedConversation ?? await createNewConversation(message);
        if (conversation.id === undefined) {
            throw new Error('Conversation ID is undefined');
        }
        
        let chatPromise: Promise<AIMessage>
        
        chatPromise = user.settings.streaming ? askMaddiAPI.Chat.streamChat(conversation.id, indexName, newMessage) : askMaddiAPI.Chat.chat(conversation.id, indexName, newMessage);

        const suggestedQuestionsPromise = askMaddiAPI.Query.getSuggestedQuestions(indexName, newMessage.content);
        const answerPromise = Promise.all([chatPromise, suggestedQuestionsPromise]);
        dispatch({ type: 'ADD_PENDING_PROMISE', payload: answerPromise });
        const [answer, suggestedQuestions] = await answerPromise;
        answer.followUpQuestions = suggestedQuestions;
        dispatch({ type: 'REMOVE_PENDING_PROMISE', payload: answerPromise });
        dispatch({ type: 'ADD_MESSAGE', payload: answer });
    }

    return (
        <div className='maddi-wrapper standalone no-header flyout-left-push'>
            <ConversationSelectionMenu 
                conversations={state.conversations} 
                onSelectConversation={handleConversationSelect} 
                selectedConversation={state.selectedConversation} 
                onSearch={handleConversationSerch}
            />
            <ChatWindow 
                onMessageSend={handleSendMessage} 
                onNewConversation={handleClearConversation} 
                isThinking={state.pendingPromises.length > 0} 
                messages={[systemMessage ?? null, ...state.messages]} 
                isLoading={state.isMessagesLoading}
            />
        </div>
    )
}