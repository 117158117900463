import './Login.scss';
import { Modal } from "react-bootstrap";
import { AuthenticationServiceContext } from '../Contexts';
import { AuthenticationService } from "../Auth/AuthenticationClient";
import { useContext, useState, useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';


export interface AskMaddiLoginPageProps {
}

export function AskMaddiLoginPage() {
    const authenticationService = useContext(AuthenticationServiceContext);
    const [ isDisabled, setIsDisabled ] = useState(false);

    useEffect(() => {
        if (authenticationService?.msalInstance.getAllAccounts().length) {
            setIsDisabled(true)
        }
        if (authenticationService?.msalInstance.getActiveAccount()) {
            setIsDisabled(true);
        }
    }, [authenticationService]);

    const handleLogin = () => {
        setIsDisabled(true);
        authenticationService?.login("redirect")
            .catch((error: Error) => {
                console.error(error);
            })
            .finally(() => setIsDisabled(false));
    }

    const handleLogout = () => {
        authenticationService?.logout("redirect")
    }

    return (
        <Modal show={true} centered className='login-wrapper'>
            <div className='logo-brand'></div>
            <div className="card unauth-div p-3 text-center">
                {/* <h1 className="card-title">Aderant AskMaddi</h1> */}
                <h5 className="card-title">Please sign-in to access this application.</h5>
                <button onClick={handleLogin} disabled={isDisabled}>Sign In</button>
                {/* <button onClick={handleLogout}>Sign Out</button> */}
            </div>
        </Modal>
    )
}