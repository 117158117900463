import Markdown from 'react-markdown';

const UPDATES = [
    {
        date: '2024-02-29',
        title: 'askMADDI 0.1.0 Release Notes',
        content: (
            'Welcome to the exciting new release askMADDI!  Since the first appearance of \n' +
            'askMADDI as a proof of concept in the 2023 Q2 Townhall we have been working hard to improve the overall askMADDI experience. ' +
            'We are excited to see what you think of the new capabilities and improvements in this release. ' +
            'Please reach out to us with any feedback or suggestions you may have as you use this new askMADDI release.\n\n' +
            '**User Experience**\n\n' +
            'The askMADDI user experience has been overhauled to align with the emerging Aderant standards for UX design.' +
            'The UX is now responsive which allows for askMADDI usage in desktop, tablet and phone form factors.\n\n' +
            '**Accuracy and Quality of Responses**\n\n' +
            'A number of architectural changes have been made behind the scenes, including the use of GPT 4.0, to improve the overall accuracy and quality of the responses askMADDI provides.\n\n' +
            '**Response Feedback**\n\n' +
            'The response feedback capabilities have been significantly improved to allow users to provide more detailed feedback including a response rating.' +
            'The feedback provided is used in our review process to further improve askMADDI accuracy and quality.\n\n' +
            '**New Topic**\n\n' +
            'A \"New Topic\" capability has been added to allow users to start a new conversation topic once they have completed their current askMADDI conversion topic.' +
            ""
        ),
    }
]

export default function Updates() {
    return (
        <div className='content-wrapper gap-20'>
            <h1>Release Notes</h1>
            <div className='repeater-wrapper gap-5'>
                {UPDATES.map((update, index) => (
                    <div key={index} className='content-repeater pad-30'>
                        <div className='header-container'>
                            <h4>{update.date}</h4>
                            <h3>{update.title}</h3>
                        </div>
                        <div>
                            <Markdown>{update.content}</Markdown>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

