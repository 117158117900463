import Message from "../Components/Message";

export const DEFAULT_WELCOME_MESSAGE = `
Hi, I am MADDI, I am your assistant for Aderant products. I am still in training but I would love to help you learn more about Aderant Products. Feel free to ask me any questions like...
* How do I revert a prebill?
* How is EA Pro configured?
* What are the steps to add new time to a prebill?
* What are the steps to customize the layout of my EA Pro dashboard?
* What are the different sources for Time Entries and their meanings?

If you don't get the answer you are looking for, try asking follow-up questions with more context or look at the options in Continue your Journey.
`;

const itimekeepQuestions = `
  * How can I prevent getting frequent verification code requests in iTimekeep?\n
  * What is the difference between Draft, Submit and Released?\n
  * What is the difference between assistant and super assistant?\n
`;

const milanaQuestions = `
  * How do I delete a duplicate matter?\n
  * What are troubleshooting steps for Milana entries not synched or missing?\n
  * What are troubleshooting steps for entries not appearing on Outlook or Attorney calendars ?\n
`;

const atoQuestions = `
  * How can I troubleshoot ATO Outlook Addin when it does not load?\n
  * I have FSLI case not syncing, what should I do?\n
  * How do I generate a Plaintiff Settlement statement?\n
`;

const viGlobalQuestions = `
  * How do you add an office in viRecruit? \n
  * How do I create a Learning Path?\n
  * How can i create a new process in Eval Wave?\n
  * How can I merge Candidates in viRecruit?\n
  * How can i add External users to an event?\n
`;

const expertQuestions = `
  * What are the steps to add new time to a prebill?
  * What are the steps to customize the layout of my EA Pro dashboard?
  * What does task audit do when enabled for a workflow?
`;

const headerMessage = `\n I am MADDI, I am your assistant for Aderant iTimekeep. I am still in training but I would love to help you learn more about Aderant Products. Feel free to ask me any questions like...`;

const footerMessage = `
  If you don't get the answer you are looking for, try asking follow-up questions with more context or look at the options in Continue your Journey.
  For more information, you can also visit the [askMADDI FAQ page](./faq)
`;

export function generatePersonalizedmilanaMessage(
  name: string,
  productName: string
) {
  return generatePersonalizedMessage(name, productName, milanaQuestions);
}

export function generatePersonalizeditimekeepMessage(
  name: string,
  productName: string
) {
  return generatePersonalizedMessage(name, productName, itimekeepQuestions);
}

export function generatePersonalizedMessage(
  name: string,
  productName: string,
  questions: string
) {
  const headerMessage = `\n I am MADDI, I am your assistant for **${productName}**. I am still in training but I would love to help you learn more about Aderant Products. Feel free to ask me any questions like...`;

  return `${name ? `Hello ${name}! ` : "Hi, "}
  ${headerMessage} 
  ${questions}
  ${footerMessage}
  `;
}

export function generatePersonalizediATOMessage(
  name: string,
  productName: string
) {
  return generatePersonalizedMessage(name, productName, atoQuestions);
}

export function generatePersonalizediviGlobalMessage(
  name: string,
  productName: string
) {
  return generatePersonalizedMessage(name, productName, viGlobalQuestions);
}

export function generatePersonalizediExpertMessage(
  name: string,
  productName: string
) {
  return generatePersonalizedMessage(name, productName, expertQuestions);
}

// Default Welcome Message
export function generatePersonalizedWelcomeMessage(name: string) {
  return (
    (name ? `Hello ${name}! ` : "Hi, ") +
    "\n \n I am MADDI, your personal assistant for **Aderant products**. I am still in training but I would love to help you learn more about Aderant Products. Feel free to ask me any questions like...\n" +
    "* What are the steps to add new time to a prebill?\n" +
    "* What are the steps to customize the layout of my EA Pro dashboard?\n" +
    "* What are the different sources for Time Entries and their meanings?\n\n" +
    "If you don't get the answer you are looking for, try asking follow-up questions with more context or look at the options in Continue your Journey.\n" +
    "For more information, you can also visit the [askMADDI FAQ page](./faq)\n"
  );
}
