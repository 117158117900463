export const IMPLEMENTED_ROUTES = [
  "Expert",
  "ATO",
  "iTimekeep",
  "Milana",
  "ProfessionalServices",
  "vi",
];
export const IMPLEMENTED_PRODUCTS = {
  Expert: "expert-v1",
  ATO: "ato",
  iTimekeep: "itimekeep",
  Milana: "milana",
  "Professional Services": "professionalservices",
  vi: "vibyaderant",
};
