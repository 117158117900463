import { NavDropdown, Form } from "react-bootstrap";
import { useAskMaddiUser } from "../../../Hooks/useAskMaddiUser";
import { SettingsIcon } from "../../Icons";
import { useAskMaddiAPI } from "../../../Hooks/useAskMaddiAPI";

export interface IChatSettingsNavDropdownProps {
}

export function ChatSettingsNavDropdown(props: IChatSettingsNavDropdownProps) {
    const [user, setUser] = useAskMaddiUser();
    const api = useAskMaddiAPI();
    const realTimeResponses = user.settings?.streaming;
    const darkMode = user.settings?.theme === 'dark';
    const enableTools = user.settings?.enableTools;
    const enableTruLensEvaluation = user.settings?.enableTruLensEvaluation;

    const handleToggleRealTimeResponses = async (e: React.MouseEvent) => {
        let userCopy = {...user};
        userCopy.settings.streaming = !realTimeResponses;
        setUser(userCopy);
        e.stopPropagation();
        await api.User.updateUserSettings(userCopy.id, userCopy.settings);
    }

    const handleToggleDarkMode = async (e: React.MouseEvent) => {
        let userCopy = {...user};
        userCopy.settings.theme = darkMode ? 'light' : 'dark';
        setUser(userCopy);
        e.stopPropagation();
        await api.User.updateUserSettings(userCopy.id, userCopy.settings);
    }

    const handleToggleTools = async (e: React.MouseEvent) => {
        let userCopy = {...user};
        userCopy.settings.enableTools = !user.settings.enableTools;
        setUser(userCopy);
        e.stopPropagation();
        await api.User.updateUserSettings(userCopy.id, userCopy.settings);
    }

    const handleToggleTruLensEvaluation = async (e: React.MouseEvent) => {
        let userCopy = { ...user };
        userCopy.settings.enableTruLensEvaluation = !user.settings.enableTruLensEvaluation;
        setUser(userCopy);
        e.stopPropagation();
        await api.User.updateUserSettings(userCopy.id, userCopy.settings);
    };

    return (
        <NavDropdown title={<SettingsIcon/>} id="ChatSettingsNavDropdown" className="ChatSettingsNavDropdown">
            <NavDropdown.Item onClick={handleToggleRealTimeResponses}>
                <Form.Check 
                    type="switch"
                    id="real-time-responses-switch"
                    label="Real-Time Responses"
                    checked={realTimeResponses}
                    onClick={handleToggleRealTimeResponses}
                />
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleToggleTools} hidden>
                <Form.Check 
                    type="switch"
                    id="tools-switch"
                    label="Tools"
                    checked={enableTools}
                    onClick={handleToggleTools}
                />
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleToggleTruLensEvaluation} disabled={realTimeResponses} hidden>
                <Form.Check
                    type="switch"
                    id="trulens-evaluation-switch"
                    label="TruLens Evaluation"
                    checked={enableTruLensEvaluation}
                    onClick={handleToggleTruLensEvaluation}
                />
            </NavDropdown.Item>
            <NavDropdown.Item onClick={handleToggleDarkMode} hidden>
                <Form.Check 
                    type="switch"
                    id="dark-mode-switch"
                    label="Dark theme"
                    checked={darkMode}
                    onClick={handleToggleDarkMode}
                />
            </NavDropdown.Item>
        </NavDropdown>
    );
}