import './MessageInputArea.scss';
import React, { useEffect } from 'react';
import {useState, useRef} from 'react';

// Declare aptrinsic as a global variable
declare const aptrinsic: any;

export interface MessageInputAreaProps {
    messageInputPlaceholder?:string
    onMessageSend: (message:string) => any,
    onNewConversation: () => any
    pastMessages?: string[],
}

function MessageInputArea({ messageInputPlaceholder, onMessageSend, onNewConversation, pastMessages }: MessageInputAreaProps) {
    const [messageTextAreaValue, setMessageTextAreaValue] = useState('');
    const [pastMessageIndex, setPastMessageIndex] = useState<number>(pastMessages?.length ?? 0);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const sendMessage = () => {
        let message = messageTextAreaValue;
        setMessageTextAreaValue('');
        onMessageSend(message);
        if (textAreaRef.current) {
           textAreaRef.current.style.height = 'auto';
        }
        // Track Search (click or enter) event
        console.log('logging search event');
        aptrinsic('track', 'Search', {"terms":"user queries for askMADDI"}); 
    }

    const handleMessageSendEvent = (e:React.FormEvent) => {
        e.preventDefault();
        sendMessage();
    };

    const handleNewConversationEvent = (e:React.FormEvent) => {
        e.preventDefault();
        setMessageTextAreaValue('');
        onNewConversation();
    };

    const handleTextAreaChange = (e:React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageTextAreaValue(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${e.target.scrollHeight}px` // padding buffer
    }

    const handleOnArrowNavigation = (direction: 'ArrowUp' | 'ArrowDown') => {
        if (pastMessages) {
            let newIndex = direction === 'ArrowUp'
            ? Math.max(pastMessageIndex - 1, 0)
            : Math.min(pastMessageIndex + 1, pastMessages.length);
            console.log(newIndex, pastMessages[newIndex])
            setPastMessageIndex(newIndex);
            setMessageTextAreaValue(pastMessages[newIndex] ?? '');
        }
    }

    const handleTextAreaKeyPress = (e:React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        } else if (e.key === "ArrowUp" && e.currentTarget.selectionStart === 0) {
            e.preventDefault();
            handleOnArrowNavigation(e.key)
        } else if (e.key === "ArrowDown" && e.currentTarget.selectionStart === messageTextAreaValue.length) {
            e.preventDefault();
            handleOnArrowNavigation(e.key)
        } 
    };

    useEffect(() => {
        setMessageTextAreaValue('');
        setPastMessageIndex(pastMessages?.length ?? 0);
    }, [pastMessages])

    return (
        <div className='MessageInputArea'>
            <div className='message-input-container'>
                <div className='controls-wrapper'>
                    <button className='message-new-conversation-btn' onClick={handleNewConversationEvent} type="submit"><label>New Topic</label></button>
                </div>
                <textarea 
                    ref={textAreaRef} 
                    className='message-input-textarea' 
                    placeholder={messageInputPlaceholder ?? "Enter your prompt here..."} 
                    value={messageTextAreaValue} 
                    onChange={handleTextAreaChange} 
                    onKeyDown={handleTextAreaKeyPress} 
                />
                <div className='controls-wrapper'>
                    <button className='message-send-btn' onClick={handleMessageSendEvent} type="submit"></button>
                    <div className="btn-maddi-toggle"><input type="checkbox"/></div>
                </div>
            </div>
        </div>
    )
}

export default MessageInputArea