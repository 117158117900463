import { getMockMessage, getMockMessages } from "../Mocks/messages";
import { HumanMessage, AIMessage, SystemMessage, Message, IMessageRating } from "../Models/Message";
import { ISourceNode } from "../Models/SourceNode";
import { BaseAPI } from "./base";


export class MessageAPI extends BaseAPI {

    public async getConversationHistory(conversationId: string): Promise<(AIMessage | SystemMessage | HumanMessage)[]> {
        if (this.isMock) {
            return this.mockFetch(getMockMessages, 3000);
        }

        try {
            let response = await this.get(`${this.apiBase}/messages/conversation/${conversationId}`, "json");
            return response.map((message: any) => {
                if (message.type === "ai") {
                    return new AIMessage(
                        message.content,
                        message.datetime,
                        message
                    );
                } else if (message.type === "system") {
                    return new SystemMessage(
                        message.content,
                        message.datetime,
                        message
                    );
                } else {
                    return new HumanMessage(
                        message.content,
                        message.datetime,
                        message
                    );
                }
            }).sort((a: Message, b: Message) => {
                return a.datetime.getTime() - b.datetime.getTime();
            });
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    private async _getMetrics(endpoint: string, user_id: string, weeks: number, model?: string): Promise<any> {
        if (this.isMock) {
            return this.mockFetch(() => ([/* mock data */]), 3000);
        }

        try {
            let uri = `${this.apiBase}${endpoint}`;
            let params = `user_id=${user_id}&weeks=${weeks}`;
            if (model) {
                params += `&model=${model}`;
            }
            let response = await this.get(`${uri}?${params}`);
            console.log(response);
            return response;
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    public async getMessageMetrics(user_id: string, weeks: number, model?: string): Promise<any> {
        return this._getMetrics('/messages/message/metrics', user_id, weeks, model);
    }

    public async getPerformanceMetrics(user_id: string, weeks: number, model?: string): Promise<any> {
        return this._getMetrics('/messages/performance/metrics', user_id, weeks, model);
    }

    public async getRatingMetrics(user_id: string, weeks: number, model?: string): Promise<any> {
        return this._getMetrics('/messages/ratings/metrics', user_id, weeks, model);
    }

    public async rateMessage(messageId: string, rating: IMessageRating): Promise<boolean> {
        if (this.isMock) {
            console.log("Mocking rating message");
            console.log(rating)
            return this.mockFetch(() => true, 3000);
        }

        try {
            await this.post(`${this.apiBase}/messages/${messageId}/rate`, rating, "json");
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    public async userMessageCount(userId: string): Promise<number> {
        if (this.isMock) {
            return this.mockFetch(() => 10, 3000);
        }

        try {
            let response = await this.get(`${this.apiBase}/messages/count_by_user/${userId}`, "json");
            return response;
        } catch (error) {
            console.error(error);
            return 0;
        }
    }

    public async userRatedMessages(userId: string, rated?: boolean): Promise<number> {
        if (this.isMock) {
            return this.mockFetch(() => 5, 3000);
        }

        try {
            let uri = `${this.apiBase}/messages/count_rated_by_user/${userId}`;
            if (rated !== undefined) {
                uri += `?helpful_rating=${rated}`;
            }
            let response = await this.get(uri, "json");
            return response;
        } catch (error) {
            console.error(error);
            return 0;
        }
    }
}